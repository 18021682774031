import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/thailand-logo.svg'


const _withScopeId = n => (_pushScopeId("data-v-1474a7b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home bg-primary" }
const _hoisted_2 = { class: "overflow-hidden" }
const _hoisted_3 = { class: "result-match" }
const _hoisted_4 = { class: "detail-list" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { style: { fontSize: '11px' } }
const _hoisted_7 = ["src"]
const _hoisted_8 = { style: { fontSize: '11px' } }
const _hoisted_9 = { class: "data-slide" }
const _hoisted_10 = { class: "container" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-lg-9" }
const _hoisted_13 = {
  id: "latest-updated-athlete",
  class: "d-flex align-items-center justify-content-between mb-3"
}
const _hoisted_14 = { class: "d-flex align-items-center text-primary" }
const _hoisted_15 = { class: "ms-2 lh-1" }
const _hoisted_16 = { class: "mb-0" }
const _hoisted_17 = { class: "text-muted" }
const _hoisted_18 = {
  key: 0,
  class: "loading-container",
  style: {"height":"300px"}
}
const _hoisted_19 = { class: "listAll" }
const _hoisted_20 = {
  id: "latest-updated-staff",
  class: "d-flex align-items-center justify-content-between mb-3"
}
const _hoisted_21 = { class: "d-flex align-items-center text-primary" }
const _hoisted_22 = { class: "ms-2 lh-1" }
const _hoisted_23 = { class: "mb-0" }
const _hoisted_24 = { class: "text-muted" }
const _hoisted_25 = {
  key: 1,
  class: "loading-container",
  style: {"height":"300px"}
}
const _hoisted_26 = { class: "listAll" }
const _hoisted_27 = {
  key: 3,
  id: "latest-injured-athlete",
  class: "d-flex align-items-center justify-content-between mb-3"
}
const _hoisted_28 = { class: "d-flex align-items-center text-primary" }
const _hoisted_29 = { class: "ms-2 lh-1" }
const _hoisted_30 = { class: "mb-0" }
const _hoisted_31 = { class: "text-muted" }
const _hoisted_32 = { class: "listAll" }
const _hoisted_33 = {
  key: 4,
  class: "thailand-athletes-wrapper my-5"
}
const _hoisted_34 = { class: "row align-items-center" }
const _hoisted_35 = { class: "col-12" }
const _hoisted_36 = { class: "d-flex align-items-center justify-content-center py-5" }
const _hoisted_37 = ["width"]
const _hoisted_38 = { class: "ms-5" }
const _hoisted_39 = {
  key: 5,
  id: "latest-updated-team",
  class: "d-flex align-items-center justify-content-between mb-3"
}
const _hoisted_40 = { class: "d-flex align-items-center text-primary" }
const _hoisted_41 = { class: "ms-2 lh-1" }
const _hoisted_42 = { class: "mb-0" }
const _hoisted_43 = { class: "text-muted" }
const _hoisted_44 = { class: "listAll" }
const _hoisted_45 = {
  key: 7,
  id: "latest-updated-tournament",
  class: "d-flex align-items-center justify-content-between mb-3"
}
const _hoisted_46 = { class: "d-flex align-items-center text-primary" }
const _hoisted_47 = { class: "ms-2 lh-1" }
const _hoisted_48 = { class: "mb-0" }
const _hoisted_49 = { class: "text-muted" }
const _hoisted_50 = { class: "listAll" }
const _hoisted_51 = {
  key: 8,
  class: "text-center"
}
const _hoisted_52 = { class: "col-lg-3" }
const _hoisted_53 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_54 = { class: "d-flex align-items-center" }
const _hoisted_55 = { class: "mb-0" }
const _hoisted_56 = ["src"]
const _hoisted_57 = { style: { fontSize: '11px' } }
const _hoisted_58 = ["src"]
const _hoisted_59 = { style: { fontSize: '11px' } }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner_competition = _resolveComponent("banner-competition")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_home_away_score = _resolveComponent("home-away-score")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_sat_icon = _resolveComponent("sat-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_sat_card = _resolveComponent("sat-card")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_team_card = _resolveComponent("team-card")!
  const _component_competition_card = _resolveComponent("competition-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_banner_competition, { latestMatch: _ctx.latestMatch }, null, 8, ["latestMatch"]),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_a_spin, {
        spinning: _ctx.loading.first
      }, null, 8, ["spinning"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.matches, (ele, idx) => {
          return (_openBlock(), _createBlock(_component_a_card, {
            style: { width: '80vw' },
            class: "details-result",
            key: `${idx}_${ele.score.home}`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_home_away_score, {
                matchName: ele.matchName[_ctx.store.state.currentLanguage],
                language: _ctx.store.state.currentLanguage,
                score: ele.score,
                id: ele.id
              }, {
                home: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      class: "img-fluid",
                      width: "60",
                      src: 
                    ele.home.logo || require('@/assets/logo-placeholder.svg')
                  
                    }, null, 8, _hoisted_5),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.store.state.currentLanguage === "th"
                      ? ele?.home?.name?.th
                      : ele?.home?.name?.en), 1)
                  ])
                ]),
                away: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "img-fluid",
                    width: "60",
                    src: ele.away.logo || require('@/assets/logo-placeholder.svg')
                  }, null, 8, _hoisted_7),
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.store.state.currentLanguage === "th"
                    ? ele?.away?.name?.th
                    : ele?.away?.name?.en), 1)
                ]),
                _: 2
              }, 1032, ["matchName", "language", "score", "id"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ])
    ]),
    _createElementVNode("section", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_a_card, { bordered: false }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_sat_icon, {
                      name: "athleteBadge",
                      style: _normalizeStyle({ fontSize: '2.5em' })
                    }, null, 8, ["style"]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("h5", _hoisted_16, _toDisplayString(_ctx.t("label.lastesAthlete")), 1),
                      _createElementVNode("small", _hoisted_17, _toDisplayString(_ctx.t("label.update")) + " " + _toDisplayString(_ctx.store.state.currentLanguage === "th"
                          ? _ctx.athletes?.lastUpdated?.th
                          : _ctx.athletes?.lastUpdated?.en), 1)
                    ])
                  ]),
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _cache[0] || (_cache[0] = () => _ctx.$router.push({ name: 'Athlete' }))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("btn.viewAll")), 1)
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.loading?.athlete)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createVNode(_component_LoadingOutlined)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_19, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.athletes.data, (data) => {
                    return (_openBlock(), _createBlock(_component_sat_card, {
                      key: data.id,
                      type: "athletes",
                      data: data,
                      page: "overview"
                    }, null, 8, ["data"]))
                  }), 128))
                ]),
                _createVNode(_component_a_divider, { style: {"height":"2px","background-color":"#b9b9c3"} }),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_sat_icon, {
                      name: "athleteBadge",
                      style: _normalizeStyle({ fontSize: '2.5em' })
                    }, null, 8, ["style"]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("h5", _hoisted_23, _toDisplayString(_ctx.t("label.lastesStaff")), 1),
                      _createElementVNode("small", _hoisted_24, _toDisplayString(_ctx.t("label.update")) + " " + _toDisplayString(_ctx.store.state.currentLanguage === "th"
                          ? _ctx.staffs?.lastUpdated?.th
                          : _ctx.staffs?.lastUpdated?.en), 1)
                    ])
                  ]),
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _cache[1] || (_cache[1] = () => _ctx.$router.push({ name: 'Staff' }))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("btn.viewAll")), 1)
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.loading?.staff)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createVNode(_component_LoadingOutlined)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_26, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staffs.data, (data) => {
                    return (_openBlock(), _createBlock(_component_sat_card, {
                      key: data.id,
                      type: "staffs",
                      data: data
                    }, null, 8, ["data"]))
                  }), 128))
                ]),
                (_ctx.athleteInjuries.data)
                  ? (_openBlock(), _createBlock(_component_a_divider, {
                      key: 2,
                      style: {"height":"2px","background-color":"#b9b9c3"}
                    }))
                  : _createCommentVNode("", true),
                (_ctx.athleteInjuries.data)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _createVNode(_component_sat_icon, {
                          name: "athleteBadge",
                          style: _normalizeStyle({ fontSize: '2.5em' })
                        }, null, 8, ["style"]),
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("h5", _hoisted_30, _toDisplayString(_ctx.t("label.lastesInjured")), 1),
                          _createElementVNode("small", _hoisted_31, _toDisplayString(_ctx.t("label.update")) + " " + _toDisplayString(_ctx.store.state.currentLanguage === "th"
                          ? _ctx.athleteInjuries?.lastUpdated?.th
                          : _ctx.athleteInjuries?.lastUpdated?.en), 1)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_32, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.athleteInjuries?.data, (data) => {
                    return (_openBlock(), _createBlock(_component_sat_card, {
                      key: data.id,
                      type: "injured",
                      data: data
                    }, null, 8, ["data"]))
                  }), 128))
                ]),
                (_ctx.total !== 0 && _ctx.teams?.data)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("div", _hoisted_35, [
                          _createElementVNode("div", _hoisted_36, [
                            _createElementVNode("img", {
                              width: _ctx.window.innerWidth < 768 ? '150' : '200',
                              src: _imports_0
                            }, null, 8, _hoisted_37),
                            _createElementVNode("div", _hoisted_38, [
                              _createElementVNode("h3", null, _toDisplayString(_ctx.t("team.thai_team")), 1),
                              _createElementVNode("h1", null, _toDisplayString(_ctx.t("label.all")) + " " + _toDisplayString(_ctx.total) + " " + _toDisplayString(_ctx.t("label.team")), 1)
                            ])
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.teams?.data)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                      _createElementVNode("div", _hoisted_40, [
                        _createVNode(_component_sat_icon, {
                          name: "athleteBadge",
                          style: _normalizeStyle({ fontSize: '2.5em' })
                        }, null, 8, ["style"]),
                        _createElementVNode("div", _hoisted_41, [
                          _createElementVNode("h5", _hoisted_42, _toDisplayString(_ctx.t("label.latestTeam")), 1),
                          _createElementVNode("small", _hoisted_43, _toDisplayString(_ctx.t("label.update")) + " " + _toDisplayString(_ctx.store.state.currentLanguage === "th"
                          ? _ctx.teams?.lastUpdated?.th
                          : _ctx.teams?.lastUpdated?.en), 1)
                        ])
                      ]),
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[2] || (_cache[2] = () => _ctx.$router.push({ path: `/teams` }))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("btn.viewAll")), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_44, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teams.data, (data) => {
                    return (_openBlock(), _createBlock(_component_team_card, {
                      key: data.id,
                      data: data
                    }, null, 8, ["data"]))
                  }), 128))
                ]),
                (_ctx.competitions.data)
                  ? (_openBlock(), _createBlock(_component_a_divider, {
                      key: 6,
                      style: {"height":"2px","background-color":"#b9b9c3"}
                    }))
                  : _createCommentVNode("", true),
                (_ctx.competitions.data)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                      _createElementVNode("div", _hoisted_46, [
                        _createVNode(_component_sat_icon, {
                          name: "athleteBadge",
                          style: _normalizeStyle({ fontSize: '2.5em' })
                        }, null, 8, ["style"]),
                        _createElementVNode("div", _hoisted_47, [
                          _createElementVNode("h5", _hoisted_48, _toDisplayString(_ctx.t("label.latestTournament")), 1),
                          _createElementVNode("small", _hoisted_49, _toDisplayString(_ctx.t("label.update")) + " " + _toDisplayString(_ctx.store.state.currentLanguage === "th"
                          ? _ctx.competitions?.lastUpdated?.th
                          : _ctx.competitions?.lastUpdated?.en), 1)
                        ])
                      ]),
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[3] || (_cache[3] = () => _ctx.$router.push({ path: `/competitions` }))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("btn.viewAll")), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_50, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.competitions.data, (data) => {
                    return (_openBlock(), _createBlock(_component_competition_card, {
                      key: data.id,
                      data: data,
                      class: ""
                    }, null, 8, ["data"]))
                  }), 128))
                ]),
                (!_ctx.visibleMoreSection)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_51, [
                      (!_ctx.loadings)
                        ? (_openBlock(), _createBlock(_component_a_button, {
                            key: 0,
                            type: "link",
                            disabled: _ctx.loading?.athlete || _ctx.loading?.staff,
                            size: "large",
                            onClick: _ctx.loadMore
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_sat_icon, {
                                name: "caretDown",
                                class: "fs-2"
                              })
                            ]),
                            _: 1
                          }, 8, ["disabled", "onClick"]))
                        : (_openBlock(), _createBlock(_component_LoadingOutlined, {
                            key: 1,
                            class: "fs-2"
                          }))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_52, [
            _createVNode(_component_a_card, {
              bordered: false,
              class: "matchCompetition"
            }, {
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("div", _hoisted_54, [
                    _createVNode(_component_a_divider, {
                      type: "vertical",
                      style: {"height":"30px","width":"3px"},
                      class: "ms-0 rounded-3 bg-primary"
                    }),
                    _createElementVNode("h5", _hoisted_55, _toDisplayString(_ctx.t("label.lastestMatch")), 1)
                  ])
                ])
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.matches, (ele, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `${idx}_${ele.score.home}`
                  }, [
                    _createVNode(_component_home_away_score, {
                      matchName: ele.matchName[_ctx.store.state.currentLanguage],
                      language: _ctx.store.state.currentLanguage,
                      score: ele.score,
                      id: ele.id
                    }, {
                      home: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("img", {
                            class: "img-fluid",
                            width: "60",
                            src: 
                          ele.home.logo ||
                          require('@/assets/logo-placeholder.svg')
                        
                          }, null, 8, _hoisted_56),
                          _createElementVNode("p", _hoisted_57, _toDisplayString(_ctx.store.state.currentLanguage === "th"
                            ? ele?.home?.name?.th
                            : ele?.home?.name?.en), 1)
                        ])
                      ]),
                      away: _withCtx(() => [
                        _createElementVNode("img", {
                          class: "img-fluid",
                          width: "60",
                          src: 
                        ele.away.logo ||
                        require('@/assets/logo-placeholder.svg')
                      
                        }, null, 8, _hoisted_58),
                        _createElementVNode("p", _hoisted_59, _toDisplayString(_ctx.store.state.currentLanguage === "th"
                          ? ele?.away?.name?.th
                          : ele?.away?.name?.en), 1)
                      ]),
                      _: 2
                    }, 1032, ["matchName", "language", "score", "id"]),
                    _createVNode(_component_a_divider, { style: {"height":"2px","background-color":"#f4f6f8"} })
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}